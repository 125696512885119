import './styles/App.scss';

import { Container, Row, Col, Image } from 'react-bootstrap';

function App() {
  return (
    <div className="general-block">
    <Container>
      <Row className="justify-content-center">
        <Col xs='6' md='2'>
          <Image src='/logo.png' className='general-logo'/>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs='12' md='6'>
          <h1 className="text-center">VPro</h1>
          <h3 className="text-center">Площадка технологических решений</h3>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs='6' md='2'>
          <a href="https://vk.com/vpro_discus" target="_blank" rel="noreferrer"><Image src='/vk_logo.png' className='social-logo'/></a>
        </Col>
        <Col xs='6' md='2'>
          <a href="https://www.youtube.com/channel/UCp6muRdASh20WOfGgrF3oPg" target="_blank" rel="noreferrer"><Image src='/youtube_logo.png' className='social-logo'/></a>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs='12' md='6'>
          <h4 className="text-center">Почта для связи  <a href="mailto:hi@vpro.pw">hi@vpro.pw</a></h4>
        </Col>
      </Row>
    </Container>
    </div>
  );
}

export default App;
